import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled-base';
import shortid from 'shortid';

import { Flex, Box, Text } from '../elements';
import ProductBrowserCard from '../ProductBrowserCard';

const Content = styled(Flex)`
  ${Text} {
    font-family: 'Axiforma-Bold';
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.grey};
    letter-spacing: 0.13px;
  }

  & > a {
    display: block;
    line-height: 0;
  }

  & > img,
  & > a > img {
    height: 100%;
    width: 160px;
    max-height: 40px;
    margin-top: 50px;
    filter: grayscale(100%);

    @media (hover: hover) {
      &:hover {
        filter: none;
      }
    }
  }
`;

const TechStack = ({ slice }) => {
  const { heading, pre_heading: preHeading } = slice.primary;

  const cards = slice.items.map(item => {
    const [
      {
        data: {
          title,
          body: [{ items }]
        }
      }
    ] = item.tech_stack_card.document;
    return {
      title,
      items: items.map(i => ({
        imageURL: i.gallery_image.url,
        imageAlt: i.gallery_image.alt,
        websiteURL: i.website_url
      }))
    };
  });

  return (
    <Box as="section" mt={6} maxWidth={1150} mx="auto">
      <Text as="p" fontSize={[1]} textAlign="center" my={3}>
        {preHeading}
      </Text>
      <Text as="h2" textAlign="center" mb={5}>
        {heading}
      </Text>
      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
      >
        {cards.map(card => (
          <ProductBrowserCard key={shortid.generate()} type="TechStack">
            <Content flexDirection="column" alignItems="center" py={3}>
              <Text as="h3">{card.title}</Text>
              {card.items.map(item => {
                return item.websiteURL ? (
                  <a
                    key={shortid.generate()}
                    href={item.websiteURL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={item.imageURL} alt={item.imageAlt} />
                  </a>
                ) : (
                  <img
                    key={shortid.generate()}
                    src={item.imageURL}
                    alt={item.imageAlt}
                  />
                );
              })}
            </Content>
          </ProductBrowserCard>
        ))}
      </Flex>
    </Box>
  );
};

TechStack.propTypes = {
  slice: PropTypes.object.isRequired
};

export default TechStack;
