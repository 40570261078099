import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { Plus, Circle } from '../shapes';

const BackgroundWorkflow = ({ variant }) => {
  const variants = [
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            left: 60,
            top: -(6 + 20),
            color: 'colors.background.pink'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            left: 180,
            top: -(40 + 12),
            color: 'colors.background.yellow',
            filled: true
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 12,
            top: -6,
            color: 'colors.background.blue2'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 12,
            left: 61,
            top: -12,
            color: 'colors.background.yellow',
            filled: true
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 13,
            top: 63,
            color: 'colors.background.pink'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            top: -16,
            right: 53,
            color: 'colors.background.yellow'
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            left: 41,
            top: -26,
            color: 'colors.background.blue2'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            top: 1,
            left: 53,
            color: 'colors.background.blue2'
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 49,
            top: -52,
            color: 'colors.background.yellow'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            right: 54,
            top: 58,
            color: 'colors.background.pink',
            filled: true
          }
        }
      ]
    }
  ];

  return (
    <>
      {variants[variant] &&
        variants[variant].components.map(({ Component, props }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component key={shortid.generate()} {...props} />
        ))}
    </>
  );
};

BackgroundWorkflow.propTypes = {
  variant: PropTypes.number
};

BackgroundWorkflow.defaultProps = {
  variant: 0
};

export default BackgroundWorkflow;
