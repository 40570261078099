import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import shortid from 'shortid';

import Facebook from './Facebook';
import Twitter from './Twitter';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({
  title,
  desc,
  banner,
  pathname,
  article,
  author,
  node,
  withJsonLd,
  social
}) => {
  const { site } = useStaticQuery(query);

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      headline,
      siteLanguage,
      ogLanguage,
      author: defaultAuthor,
      organization,
      twitter,
      facebook
    }
  } = site;

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')
  const authorName = author || defaultAuthor;

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    url: siteUrl,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: authorName
    },
    copyrightHolder: {
      '@type': 'Person',
      name: authorName
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: authorName
    },
    publisher: {
      '@type': 'Person',
      name: authorName
    },
    datePublished: '2019-09-30T11:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`
    }
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage'
      },
      position: 1
    }
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: authorName
      },
      copyrightHolder: {
        '@type': 'Person',
        name: authorName
      },
      copyrightYear: '2020',
      creator: {
        '@type': 'Person',
        name: authorName
      },
      publisher: {
        '@type': 'Organization',
        name: organization,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`
        }
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: siteLanguage,
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image
      },
      mainEntityOfPage: seo.url
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title
      },
      position: 2
    });
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement
  };

  const renderSocialMetaTags = data =>
    data.map(slice => {
      switch (slice.slice_type) {
        case 'open_graph':
          return (
            <Facebook
              key={shortid.generate()}
              desc={slice.primary.og_description.text || seo.description}
              image={
                `${siteUrl}${slice.primary.og_image.localFile.publicURL}` ||
                seo.image
              }
              title={slice.primary.og_title.text || seo.title}
              type={article ? 'article' : 'website'}
              url={seo.url}
              locale={ogLanguage}
              name={facebook}
            />
          );

        case 'twitter_card':
          return (
            <Twitter
              key={shortid.generate()}
              title={slice.primary.twitter_title.text || seo.title}
              image={
                `${siteUrl}${slice.primary.twitter_image.localFile.publicURL}` ||
                seo.image
              }
              desc={slice.primary.twitter_description.text || seo.description}
              username={twitter}
            />
          );

        default:
          return null;
      }
    });

  return (
    <>
      <Helmet title={seo.title} titleTemplate="%s | JAMchefs.com">
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* <meta name="gatsby-starter" content="Gatsby Starter Prismic" /> */}
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {withJsonLd && !article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {withJsonLd && article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )}
        {withJsonLd && (
          <script type="application/ld+json">
            {JSON.stringify(breadcrumb)}
          </script>
        )}
      </Helmet>
      {social ? (
        renderSocialMetaTags(social)
      ) : (
        <>
          <Facebook
            desc={seo.description}
            image={seo.image}
            title={seo.title}
            type={article ? 'article' : 'website'}
            url={seo.url}
            locale={ogLanguage}
            name={facebook}
          />
          <Twitter
            title={seo.title}
            image={seo.image}
            desc={seo.description}
            username={twitter}
          />
        </>
      )}
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  node: PropTypes.object,
  withJsonLd: PropTypes.bool,
  author: PropTypes.string,
  social: PropTypes.array
};

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  article: false,
  node: null,
  withJsonLd: true,
  author: null,
  social: null
};

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        siteLanguage
        ogLanguage
        author
        organization
        twitter
        facebook
      }
    }
  }
`;
