import React from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import { Box, Flex, Text } from '../elements';
import ProductBrowserCard from '../ProductBrowserCard';
import { PrimaryButton } from '../shared/Buttons';

const Products = ({ slice }) => {
  const { title } = slice.primary;

  return (
    <Box mb={6} id="services" maxWidth={1150} mx="auto">
      <Text as="h2" textAlign="center" mb={5}>
        {title}
      </Text>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection={['column', 'column', 'column', 'row']}
      >
        {slice.items.map((el, i) => (
          <ProductBrowserCard
            key={shortid.generate()}
            element={el}
            type={slice.slice_type}
            variant={i}
          />
        ))}
      </Flex>
      <Flex justifyContent="center">
        <PrimaryButton to="/contact">Get in touch</PrimaryButton>
      </Flex>
    </Box>
  );
};

Products.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Products;
