import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import shortid from 'shortid';
import styled from '@emotion/styled';

import { FlexContainer, Flex, Box, Text } from '../elements';

const FooterWrapper = styled.div`
  position: relative;
`;

const SocialMenu = styled.ul`
  display: flex;
  flex-direction: row;

  & li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;

    &:hover {
      img {
        filter: invert(1) sepia(0) saturate(5) hue-rotate(175deg);
        transition: 0.2s ease-in-out;
      }
    }
  }

  & li:not(:first-of-type) {
    margin-left: 10px;
  }
`;

const LatestBlogPostsRoot = styled.div`
  & li {
    margin-bottom: 8px;
  }
`;

const LatestBlogPosts = ({ posts }) => (
  <LatestBlogPostsRoot>
    <Text fontWeight="bold" mb={2}>
      Latest from our blog:
    </Text>
    <ul>
      {posts.map((post, i) => (
        <li key={shortid.generate()}>
          <Link to={post.linkURL}>{post.title}</Link>
        </li>
      ))}
    </ul>
  </LatestBlogPostsRoot>
);

LatestBlogPosts.propTypes = {
  posts: PropTypes.array.isRequired
};

const Footer = ({ data }) => {
  const {
    logo,
    copyright,
    address,
    email,
    phone,
    footerNavItems,
    footerSocialItems
  } = data;

  const { allPrismicBlog } = useStaticQuery(query);

  const posts = allPrismicBlog.edges.map(({ node }) => ({
    title: node.data.title.text,
    linkURL: `/blog/${node.uid}`
  }));

  return (
    <FooterWrapper id="contact">
      <FlexContainer
        as="footer"
        flexDirection="column"
        justifyContent="space-between"
        alignItems={['center', 'stretch']}
        px="15px"
        style={{ position: 'relative' }}
      >
        <Flex
          flexDirection={['column', 'row']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box mb={[4, 0]}>
            <Link to="/">
              <img src={logo.localFile.publicURL} alt={logo.alt} height={55} />
            </Link>
          </Box>
          <LatestBlogPosts posts={posts} />
          <SocialMenu>
            {footerSocialItems.map(item => (
              <li key={shortid.generate()}>
                <a
                  href={item.link && item.link.url}
                  target={item.link && item.link.target}
                  rel="noopener noreferrer"
                  title={item.title}
                  aria-label={item.aria_label}
                >
                  <img
                    src={item.icon.localFile.publicURL}
                    alt={item.icon.alt}
                  />
                </a>
              </li>
            ))}
          </SocialMenu>
        </Flex>
        <Text fontSize={0} color="primary" my={4}>
          {copyright}
        </Text>
      </FlexContainer>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  data: PropTypes.object.isRequired
};

export default Footer;

const query = graphql`
  query LatestBlogPosts {
    allPrismicBlog(
      limit: 3
      sort: { order: DESC, fields: first_publication_date }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;
