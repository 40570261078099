import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import shortid from 'shortid';

import { Flex, Box, Text } from '../elements';
import { BackgroundWorkflow } from '../backgrounds';

const SliceRoot = styled(Box)`
  & .preheading {
    display: inline-block;
    max-width: 60%;
  }
`;
const Phase = styled(Box)`
  position: relative;
  text-align: center;
  img {
    object-fit: contain;
    width: 85px;
    height: 70px;
  }
`;

const Workflow = ({ slice }) => {
  const { heading, pre_heading: preHeading } = slice.primary;
  return (
    <SliceRoot as="section" mt={6} maxWidth={1150} mx="auto" textAlign="center">
      <Text
        as="p"
        className="preheading"
        fontSize={[1]}
        textAlign="center"
        my={4}
      >
        {preHeading}
      </Text>
      <Text as="h2" textAlign="center">
        {heading}
      </Text>
      <Flex
        justifyContent="space-between"
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems="center"
        px={4}
      >
        {slice.items.map((item, i) => (
          <Phase
            key={shortid.generate()}
            width={[1, 1 / 2, 1 / 3, 1 / 4]}
            mt={6}
            p={3}
          >
            <img src={item.icon.url} alt={item.icon.alt} />
            <Text as="h3" color="primary" textAlign="center" my={3}>
              {item.title}
            </Text>
            <Text textAlign="center" color="text">
              {item.description}
            </Text>
            <BackgroundWorkflow variant={i} />
          </Phase>
        ))}
      </Flex>
    </SliceRoot>
  );
};

Workflow.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Workflow;
