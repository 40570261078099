import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import Plx from 'react-plx';

import { media, em } from '../../shared/util';
import linkResolver from '../../utils/linkResolver';

import HamburgerButton from './HamburgerButton';
import { PrimaryButton } from '../shared/Buttons';

const headerProps = [
  {
    start: 0,
    end: 50,
    properties: [
      {
        startValue: 'rgba(250, 250, 250, 0)',
        endValue: 'rgba(255 , 255, 255, 1)',
        property: 'backgroundColor'
      },
      {
        startValue: 'rgba(0, 0, 0, 0)',
        endValue: 'rgba(0, 0, 0, 0.1)',
        property: 'borderBottomColor'
      }
    ]
  }
];

const HeaderWrapper = styled.div`
  position: relative;
`;

const Menu = styled.header`
  position: relative;
  display: flex;
  width: 100%;
  height: ${em(90)};
  justify-content: space-between;
  align-items: center;
  padding: 0 ${em(25)};

  & ${PrimaryButton} {
    display: none;
  }

  ${media.desktop`
    align-items: center;
    max-width: 73.875em;
    margin: 0 auto;
    height: ${em(100)};
    background-color: transparent;

    & ${PrimaryButton} {
      display: inline-flex;
    }
  `};
`;

const MenuContainer = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  transition: opacity 0.1s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9);
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};

  ${media.desktop`
    top: 50%;
    left: 50%;
    width: 600px;
    height: 100%;
    flex-direction: row;
    justify-content: space-around;
    transform: translate(-50%, -50%);
    background-color: transparent;
    visibility: visible;
    opacity: 1;
  `};
`;

const MenuItem = styled.li`
  padding: 20px 0;
  a {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.13px;
    line-height: ${props => props.theme.lineHeights[1]}px;
  }

  ${media.desktop`
    & {
      padding: 30px 0;

      a {
        font-size: inherit;

        &:hover {
          color: rgba(247, 119, 212, 1);
        }
      }
    }
  `}
`;

const Logo = styled.div`
  margin: 0 auto;
  img {
    height: 50px;
  }

  ${media.desktop`
    margin: 0;

    img {
      height: 56px;
    }
  `};
`;

const Hamburger = styled(HamburgerButton)`
  display: inline-block;
  ${media.desktop`display: none;`}
`;

const MenuList = ({ items, isOpen, handleClick }) => (
  <MenuContainer isOpen={isOpen}>
    {items.map(item => (
      <MenuItem key={item.link.id}>
        <Link to={linkResolver(item.link)}>{item.text}</Link>
      </MenuItem>
    ))}
  </MenuContainer>
);

MenuList.propTypes = {
  items: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired
};

const Header = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { navItems, logo, button } = data;
  const { buttonTitle, buttonLink } = button;

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <HeaderWrapper>
      <Plx
        parallaxData={headerProps}
        style={{
          position: 'fixed',
          width: '100%',
          zIndex: '1',
          borderBottom: '1px solid',
          transition: `all 0.1s cubic-bezier(0.1, 0.2, 1.3, 0.1)`
        }}
      >
        <Menu>
          <Logo>
            <Link to="/">
              <img src={logo.localFile.publicURL} alt={logo.alt} />
            </Link>
          </Logo>
          <MenuList
            isOpen={isOpen}
            items={navItems}
            handleClick={handleClick}
          />

          {buttonLink.link_type === 'Document' ? (
            <PrimaryButton to={linkResolver(buttonLink)}>
              {buttonTitle}
            </PrimaryButton>
          ) : (
            <PrimaryButton
              href={buttonLink.url}
              target={buttonLink.target}
              rel="noopener noreferrer"
            >
              {buttonTitle}
            </PrimaryButton>
          )}

          <Hamburger
            width={28}
            rotate={0}
            height={18}
            color="#5F0082"
            isOpen={isOpen}
            strokeWidth={3}
            borderRadius={1}
            animationDuration={0.2}
            menuClicked={handleClick}
          />
        </Menu>
      </Plx>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  data: PropTypes.object.isRequired
};

export default Header;
