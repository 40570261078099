import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import styled from '@emotion/styled';

import { Flex, Box, Text } from '../elements';

const ImageLink = styled(Box)`
  text-align: center;

  img {
    width: 120px;
    height: 80px;
    object-fit: contain;
  }
`;

const ImageStrip = ({ slice }) => {
  const { items } = slice;
  const { heading, pre_heading: preHeading } = slice.primary;
  return (
    <Box>
      {preHeading && (
        <Text as="p" textAlign="center" my={4}>
          {preHeading}
        </Text>
      )}
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems="center"
        justifyContent="space-around"
        my={4}
      >
        {items.map(item => {
          const { link, image } = item;
          return (
            <ImageLink
              width={[1, 1, 1 / items.length]}
              mb={[3, 3, 0]}
              as="a"
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              key={shortid.generate()}
            >
              <img src={image.localFile.publicURL} alt={image.alt} />
            </ImageLink>
          );
        })}
      </Flex>
    </Box>
  );
};

ImageStrip.propTypes = {
  slice: PropTypes.object.isRequired
};

export default ImageStrip;
