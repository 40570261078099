import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import shortid from 'shortid';

import Browser from '../Browser';
import { em } from '../../shared/util';
import { Flex, Box, Text } from '../elements';
import { BackgroundFeatures } from '../backgrounds';

const Content = styled(Flex)`
  height: calc(100% - 21px);
  background-color: ${({ theme }) => theme.colors.lightBlue};

  img {
    width: ${em(90)};
    height: ${em(90)};
    object-fit: contain;
  }
`;

const styles = { position: 'relative' };

const Features = ({ slice }) => {
  const { heading, pre_heading: preHeading } = slice.primary;
  return (
    <Box as="section" mt={6} style={styles} maxWidth={1150} mx="auto">
      <Text as="p" fontSize={[1]} textAlign="center" my={4} color="primary">
        {preHeading}
      </Text>
      <Text as="h2" textAlign="center" mb={5}>
        {heading}
      </Text>
      <Flex justifyContent="space-between" flexWrap="wrap">
        {slice.items.map(item => (
          <Box key={shortid.generate()} width={390} mb={5} mx="auto">
            <Browser small maxWidth={389}>
              <Content justifyContent="center" alignItems="center">
                <img src={item.icon.url} alt={item.icon.alt} />
              </Content>
            </Browser>

            <Text as="h3" color="primary" textAlign="center" mt={4} mb={3}>
              {item.title}
            </Text>
            <Text textAlign="center" color="text">
              {item.description}
            </Text>
          </Box>
        ))}
      </Flex>
      <BackgroundFeatures />
    </Box>
  );
};

Features.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Features;
