import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Box, Text } from '../elements';

const ParagraphRoot = styled.div`
  & p {
    color: ${themeGet('colors.black')};
    margin-bottom: ${themeGet('space.3')}px;
  }
  & a {
    font-weight: bold;
  }
`;

const TextBlock = ({ slice }) => {
  const { title1, paragraph } = slice.primary;
  return (
    <Box as="section" my={6}>
      <Text as="h2" textAlign="center" mb={5}>
        {title1.text}
      </Text>
      <ParagraphRoot dangerouslySetInnerHTML={{ __html: paragraph.html }} />
    </Box>
  );
};

TextBlock.propTypes = {
  slice: PropTypes.object.isRequired
};

export default TextBlock;
