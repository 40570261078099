import styled from '@emotion/styled';
import theme from '../../tokens/theme';

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 37px;
  font-weight: ${theme.fontWeights.medium};
  letter-spacing: ${theme.letterSpacings.buttons};
  border: none;

  &:active,
  &:hover,
  &:focus {
    outline: none;
  }
`;

export default Button;
