import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Box, Text } from '../elements';
import ButtonPrimary from '../button/ButtonPrimary';
import { BackgroundCTA } from '../backgrounds';

const Section = styled(Box)`
  position: relative;
  text-align: center;

  a {
    margin: 0 auto;
  }
`;

const CTA = ({ slice }) => {
  const {
    title: heading,
    description: preHeading,
    cta: {
      document: [{ data: cta }]
    }
  } = slice.primary;
  return (
    <Section
      as="section"
      my={6}
      mx="auto"
      width={[1, 1 / 2]}
      flexDirection="column"
      alignItems="center"
    >
      <Text as="p" fontSize={[1]} textAlign="center" my={4} color="primary">
        {preHeading}
      </Text>
      <Text as="h2" textAlign="center" mb={4}>
        {heading}
      </Text>
      <ButtonPrimary href={cta.link} target="_blank" rel="noopener noreferrer">
        {cta.title.text}
      </ButtonPrimary>
      <BackgroundCTA display={['none', 'block']} />
    </Section>
  );
};

CTA.propTypes = {
  slice: PropTypes.object.isRequired
};

export default CTA;
