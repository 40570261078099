import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Plx from 'react-plx';

import { Container, Box, Text } from '../elements';
import { Plus, Circle } from '../shapes';

const parallaxDataPeach = [
  {
    start: 100,
    end: 1000,
    properties: [
      {
        startValue: 0,
        endValue: 10,
        property: 'translateX'
      },
      {
        startValue: 0,
        endValue: 150,
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: -10,
        property: 'rotate'
      }
    ]
  }
];

const parallaxDataStrawberry = [
  {
    start: 80,
    end: 500,
    properties: [
      {
        startValue: 0,
        endValue: 15,
        property: 'translateX'
      },
      {
        startValue: 0,
        endValue: 30,
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: 5,
        property: 'rotate'
      }
    ]
  }
];

const parallaxDataRaspberry = [
  {
    start: 0,
    end: 900,
    properties: [
      {
        startValue: 0,
        endValue: 50,
        property: 'translateY'
      },
      {
        startValue: 0,
        endValue: -10,
        property: 'rotate'
      }
    ]
  }
];

const transition = speed => ({
  transition: `all ${speed / 10}s cubic-bezier(0.1, 0.2, 0.3, 0.1)`
});

const Hero = ({ slice }) => {
  const { title, description, image } = slice.primary;

  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: {
            sourceInstanceName: { eq: "images" }
            relativeDirectory: { eq: "fruits" }
          }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 140, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  );
  const fruits = allFile.edges.map(edge => edge.node);

  const peach8046 = fruits.find(fruit => fruit.name === 'peach.8046');
  const strawberry8041 = fruits.find(fruit => fruit.name === 'strawberry.8041');
  const raspberry8050 = fruits.find(fruit => fruit.name === 'raspberry.8050');

  return (
    <Container
      id="top"
      as="article"
      maxWidth="793px"
      justifyContent="center"
      flexDirection={['column', 'row']}
    >
      {/* Hero title starts here */}
      <Box as="section" flexDirection="column" maxWidth="793px" mb={5}>
        <Text as="h1" color="primary" textAlign="center" mb={3}>
          {title}
        </Text>
        <Box width="73%" mx="auto">
          <Text as="p" fontSize={[2]} lineHeight="24px" textAlign="center">
            {description}
          </Text>
        </Box>
      </Box>
      {/* Hero title ends here */}

      <Box mt={[0, 0, 90]} mb={5} style={{ position: 'relative' }}>
        <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        {/*  SHAPES */}

        <Box
          display={['none', 'block']}
          width={230}
          style={{ position: 'absolute', top: 50, left: -140 }}
        >
          <Plx parallaxData={parallaxDataPeach} style={transition(5.1)}>
            <Img
              fluid={peach8046.childImageSharp.fluid}
              alt="Peach image with parallax effect"
            />
          </Plx>
        </Box>
        <Box
          width={[90, 140]}
          style={{ position: 'absolute', top: -50, left: '35%' }}
        >
          <Plx parallaxData={parallaxDataStrawberry} style={transition(4.8)}>
            <Img
              fluid={strawberry8041.childImageSharp.fluid}
              alt="Strawberry image with parallax effect"
            />
          </Plx>
        </Box>
        <Box
          width={[90, 140]}
          style={{ position: 'absolute', bottom: 0, left: '60%' }}
        >
          <Plx parallaxData={parallaxDataRaspberry} style={transition(4.6)}>
            <Img
              fluid={raspberry8050.childImageSharp.fluid}
              alt="Raspberry image with parallax effect"
            />
          </Plx>
        </Box>

        <Box display={['none', 'none', 'block']}>
          <Circle
            size={20}
            top={-20}
            left={120}
            color="colors.background.pink"
          />
          <Circle
            size={77}
            top={-90}
            right={-20}
            color="colors.background.pink"
          />
          <Circle
            size={32}
            top={-60}
            left={450}
            color="colors.background.blue2"
            filled
          />
          <Circle
            size={14}
            bottom={-20}
            left={750}
            color="colors.primary"
            filled
          />
          <Plus size={52} right={-60} top={350} color="colors.primary" />
          <Plus
            size={52}
            left={-80}
            top={480}
            color="colors.background.yellow"
          />
        </Box>
      </Box>
    </Container>
  );
};

Hero.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Hero;
