import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import {
  Hero,
  Products,
  FeaturedProject,
  Features,
  Workflow,
  TechStack,
  CTA,
  CTABanner,
  Testimonials,
  TextBlock,
  Text,
  ContactForm,
  HeroBanner,
  ImageStrip,
  Services,
  CopySection,
  ImageSection
} from './slices';

const SlicesRenderer = ({ slices }) => {
  if (!slices || slices.length === 0) return null;

  return slices.map(slice => {
    switch (slice.slice_type) {
      case 'hero':
        return <Hero key={shortid.generate()} slice={slice} />;

      case 'products':
        return <Products key={shortid.generate()} slice={slice} />;

      case 'featured':
        return <FeaturedProject key={shortid.generate()} slice={slice} />;

      case 'features':
        return <Features key={shortid.generate()} slice={slice} />;

      case 'workflow':
        return <Workflow key={shortid.generate()} slice={slice} />;

      case 'tech_stack':
        return <TechStack key={shortid.generate()} slice={slice} />;

      case 'cta':
        return <CTA key={shortid.generate()} slice={slice} />;

      case 'cta_banner':
        return <CTABanner key={shortid.generate()} slice={slice} />;

      case 'testimonials':
        return <Testimonials key={shortid.generate()} slice={slice} />;

      case 'text_block':
        return <TextBlock key={shortid.generate()} slice={slice} />;

      case 'text':
        return <Text key={shortid.generate()} slice={slice} />;

      case 'contact_form':
        return <ContactForm key={shortid.generate()} slice={slice} />;

      case 'hero_banner':
        return <HeroBanner key={shortid.generate()} slice={slice} />;

      case 'image_strip':
        return <ImageStrip key={shortid.generate()} slice={slice} />;

      case 'services':
        return <Services key={shortid.generate()} slice={slice} />;

      case 'copy_section':
        return <CopySection key={shortid.generate()} slice={slice} />;

      case 'image_section':
        return <ImageSection key={shortid.generate()} slice={slice} />;

      default:
        return null;
    }
  });
};

SlicesRenderer.propTypes = {
  slices: PropTypes.array.isRequired
};

export default SlicesRenderer;
