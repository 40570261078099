import React from 'react';
import { Plus, Circle } from '../shapes';

const BackgroundFeatures = () => (
  <>
    <Circle size={20} top={1} left={180} color="colors.background.pink" />
    <Plus size={52} right={140} top={-60} color="colors.background.yellow" />
    <Circle size={12} top={300} left={1} color="colors.primary" filled />
    <Plus size={52} left={1} top={800} color="colors.background.blue2" />
    <Plus size={52} right={1} top={850} color="colors.background.pink" />
    <Circle size={20} top={1080} left={50} color="colors.background.yellow" />
  </>
);

export default BackgroundFeatures;
