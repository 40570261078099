import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

import { Box, Text } from '../elements';

const ParagraphRoot = styled.div`
  max-width: 600px;
  margin: 0 auto;

  & p {
    color: ${themeGet('colors.black')};
    margin-bottom: ${themeGet('space.3')}px;
  }
  & a {
    font-weight: bold;
  }
`;

const ImageSection = ({ slice }) => {
  const { image, caption } = slice.primary;
  const isSVG = image.localFile.extension === 'svg';

  return (
    <Box as="section" my={4} maxWidth={600} mx="auto" textAlign="center">
      {isSVG ? (
        <img
          alt={image.alt}
          src={image.localFile.publicURL}
          width={image.dimensions.width}
        />
      ) : (
        <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
      )}
      {caption && (
        <Text as="p" fontSize={0} color="grey" mt={2}>
          {caption}
        </Text>
      )}
    </Box>
  );
};

ImageSection.propTypes = {
  slice: PropTypes.object.isRequired
};

export default ImageSection;
