import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import styled from '@emotion/styled';

import { Box, Flex, Text } from '../elements';

const ServiceRoot = styled(Box)`
  & img {
    display: inline-block;
    width: 200px;
    height: 80px;
    object-fit: contain;
  }
`;

const Service = ({ name, description, image }) => (
  <ServiceRoot width={[1, 1, 1 / 3]} textAlign="center" mb={[4, 4, 0]}>
    <img src={image.localFile.publicURL} alt={image.alt} />
    <Text my={4} dangerouslySetInnerHTML={{ __html: name.html }} />
    {description}
  </ServiceRoot>
);

Service.propTypes = {
  name: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired
};

const Services = ({ slice }) => {
  const { items } = slice;
  return (
    <Flex flexDirection={['column', 'column', 'row']}>
      {items.map(({ service_name: serviceName, description, image }) => (
        <Service
          key={shortid.generate()}
          name={serviceName}
          description={description}
          image={image}
        />
      ))}
    </Flex>
  );
};

Services.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Services;
