import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { Plus, Circle } from '../shapes';

const BackgroundProducts = ({ variant }) => {
  const variants = [
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            right: 95,
            top: 150,
            color: 'colors.background.yellow'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            right: 65,
            top: 30,
            color: 'colors.background.blue2',
            filled: true
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            left: 66,
            top: 30,
            color: 'colors.primary'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            left: 66,
            top: 130,
            color: 'colors.background.blue2'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            left: 87,
            top: 30,
            color: 'colors.primary',
            filled: true
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 67,
            top: 130,
            color: 'colors.background.pink'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            left: 66,
            top: 50,
            color: 'colors.primary'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            left: 125,
            top: 160,
            color: 'colors.background.blue2',
            filled: true
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 52,
            top: 30,
            color: 'colors.background.yellow'
          }
        }
      ]
    },
    {
      components: [
        {
          Component: Circle,
          props: {
            size: 20,
            top: 1,
            left: 53,
            color: 'colors.background.blue2'
          }
        },
        {
          Component: Plus,
          props: {
            size: 52,
            right: 49,
            top: -52,
            color: 'colors.background.yellow'
          }
        },
        {
          Component: Circle,
          props: {
            size: 12,
            right: 54,
            top: 58,
            color: 'colors.background.pink',
            filled: true
          }
        }
      ]
    }
  ];

  return (
    <>
      {variants[variant] &&
        variants[variant].components.map(({ Component, props }) => (
          <Component
            key={shortid.generate()}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            style={{ zIndex: 0 }}
            zIndex={0}
          />
        ))}
    </>
  );
};

BackgroundProducts.propTypes = {
  variant: PropTypes.number
};

BackgroundProducts.defaultProps = {
  variant: 0
};

export default BackgroundProducts;
