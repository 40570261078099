import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { em } from '../shared/util';

const Window = styled.div`
  height: ${({ scale }) => (scale === 2 ? '215px' : 'auto')};
  width: ${({ scale }) => (scale === 2 ? '75%' : '90%')};
  max-width: ${({ maxWidth }) => maxWidth}px;

  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme, scale }) => theme.radii[5] / scale}px;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.bpAliases.desktop}) {
    height: ${({ scale }) => (scale === 2 ? '300px' : 'auto')};
    width: 100%;
    margin: 0;
  }
`;

const Toolbar = styled.div`
  padding: ${({ scale }) => 14 / scale}px;
  padding-top: ${em(19)};
  padding-left: ${em(22)};
  box-sizing: border-box;
  font-size: ${({ scale }) => 14 / scale}px;
  text-align: left;

  /* Browser toolbar dots */
  & > span {
    display: inline-block;
    width: ${({ scale }) => 11 / scale}px;
    height: ${({ scale }) => 11 / scale}px;
    margin-right: ${({ scale }) => 12 / scale}px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.black};
  }

  & > span:first-of-type {
    background-color: ${({ theme }) => theme.colors.browser.red};
  }

  & > span:nth-of-type(2) {
    background-color: ${({ theme }) => theme.colors.browser.orange};
  }

  & > span:nth-of-type(3) {
    background-color: ${({ theme }) => theme.colors.browser.green};
  }
`;

const Browser = ({ children, small, maxWidth }) => (
  <Window scale={small ? 2 : 1} maxWidth={maxWidth}>
    <Toolbar scale={small ? 2 : 1}>
      <span />
      <span />
      <span />
    </Toolbar>
    {children}
  </Window>
);

Browser.propTypes = {
  children: PropTypes.node.isRequired,
  small: PropTypes.bool,
  maxWidth: PropTypes.number
};

Browser.defaultProps = {
  small: false,
  maxWidth: 0
};

export default Browser;
