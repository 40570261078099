import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { media } from '../shared/util';

import { Text, Box, Flex } from './elements';
// import ButtonPrimary from './button/ButtonPrimary';
import { BackgroundProducts } from './backgrounds';

const Card = styled.div`
  border: none;
  min-height: 400px;
  width: 100%;
  max-width: 371px;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  border-radius: ${props => props.theme.radii[5]}px;
  margin-bottom: ${props => props.theme.space[4]}px;

  ${media.desktop`
    width: calc((100% / 3) - 20px);
  `};
`;

const DotsWrapper = styled.div`
  display: flex;
  margin: 19px 0 0 22px;

  div {
    width: 11px;
    height: 11px;
    margin-right: 12px;
    border-radius: 50%;
    background-color: green;
  }

  div:first-of-type {
    background-color: #ed1c24;
  }

  div:nth-of-type(2) {
    background-color: #f7941d;
  }

  div:nth-of-type(3) {
    background-color: #8dc63f;
  }
`;

const CardBrowserButtons = () => (
  <DotsWrapper>
    <div />
    <div />
    <div />
  </DotsWrapper>
);

const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  height: 185px;
  margin: 0 auto 30px;

  img {
    width: 82px;
    height: 100%;
  }
`;

const ProductBrowserCard = ({ type, element, children, variant }) => (
  <Card>
    <CardBrowserButtons />

    {type === 'products' && (
      <Box style={{ position: 'relative' }} height="100%" px={15}>
        <BackgroundProducts variant={variant} />
        <ImageBox>
          <img src={element.image.localFile.url} alt={element.image.alt} />
        </ImageBox>
        <Text as="h3" textAlign="center" mb={2}>
          {element.title}
        </Text>
        <Text as="p" textAlign="center">
          {element.description}
        </Text>
        {/* <Flex
          width="100%"
          justifyContent="center"
          style={{ position: 'absolute', bottom: '60px' }}
        >
          <ButtonPrimary
            href={element.cta.document[0].data.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {element.cta.document[0].data.title.text}
          </ButtonPrimary>
        </Flex> */}
      </Box>
    )}

    {type === 'TechStack' && children}
  </Card>
);

ProductBrowserCard.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  element: PropTypes.object,
  variant: PropTypes.number
};

// TODO: Refactor ProductBrowserCard
ProductBrowserCard.defaultProps = {
  children: React.createElement('div'),
  element: {},
  variant: 0
};

export default ProductBrowserCard;
