import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from '@styled-system/theme-get';
import useForm from 'react-hook-form';

import { Flex, Text } from '../elements';

const ContentRoot = styled.div``;

const Form = styled.form`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: ${themeGet('space.5')}px;

  p.error {
    color: ${themeGet('colors.primary')};
    font-size: 0.8rem;
  }

  p.error::before {
    display: inline;
    content: '✋ ';
  }
`;

const baseInput = props => css`
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid ${themeGet('colors.primary')(props)};
  border-radius: ${themeGet('radii.6')(props)}px;
  padding: 10px 15px;
  margin: ${themeGet('space.4')(props)}px 0;
  font-size: ${themeGet('fontSizes.1')(props)}px;
  outline: none;
  transition: 0.25s;

  &:focus {
    border: 2px solid ${themeGet('colors.pink')(props)};
  }
`;

const Input = styled.input`
  ${baseInput}

  &[type='checkbox'] {
    display: inline;
  }

  &[type='submit'] {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.primary')};
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 10px;

    @media (hover: hover) {
      &:hover {
        background-color: ${themeGet('colors.pink')};
      }
    }

    &:disabled {
      background-color: ${themeGet('colors.grey')};
    }
  }
`;

const Textarea = styled.textarea`
  ${baseInput}
  border-radius: 24px;
  resize: vertical;
`;

const ThankYouMessage = styled(Flex)`
  min-height: 300px;
`;

const ContactForm = ({ slice }) => {
  const { heading, subheading } = slice.primary;

  const [working, setWorking] = useState(false);
  const [success, setSuccess] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  const encode = data =>
    Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');

  const onSubmit = formData => {
    setWorking(true);
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formData })
    })
      .then(result => {
        setWorking(false);
        setSuccess(true);
      })
      .catch(error => {
        setWorking(false);
      });
  };

  return (
    <ContentRoot>
      <Text textAlign="center" as="h2">
        {heading}
      </Text>
      <Text textAlign="center" as="p" mt={3}>
        {subheading}
      </Text>
      {success ? (
        <ThankYouMessage
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text textAlign="center" as="h3">
            Thanks for contacting us!
          </Text>
          <Text textAlign="center" as="p" mt={3}>
            We'll reach out to you shortly.
          </Text>
        </ThankYouMessage>
      ) : (
        <Form
          onSubmit={handleSubmit(onSubmit)}
          name="contact"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <Input
            placeholder="Your name *"
            type="text"
            name="name"
            ref={register({ required: true })}
          />
          {errors.name && <p className="error">Name is required.</p>}
          <Input
            placeholder="Your email *"
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          {errors.email && (
            <p className="error">
              Email is required. We use it to contact you.
            </p>
          )}
          <Textarea
            placeholder="Your message *"
            rows={5}
            name="message"
            spellCheck={false}
            ref={register({ required: true })}
          />
          {errors.message && <p className="error">Message is required.</p>}
          <Input type="submit" value="Send message" disabled={working} />
        </Form>
      )}
    </ContentRoot>
  );
};

ContactForm.propTypes = {
  slice: PropTypes.object.isRequired
};

export default ContactForm;
