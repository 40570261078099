import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

const Circle = styled.div`
  z-index: -1;
  position: absolute;
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.right && `right: ${props.right}px;`}
  ${props => props.top && `top: ${props.top}px;`}
  ${props => props.bottom && `bottom: ${props.bottom}px;`}
  width: ${({ size = 100 }) => size}px;
  height: ${({ size = 100 }) => size}px;
  background: ${({ color = 'red', filled }) =>
    filled ? themeGet(color, 'green') : 'transparent'};
  border-radius: 50%;
  border: ${props => props.size / 5}px solid
    ${({ color = 'red' }) => themeGet(color, 'green')};
`;

export default Circle;
