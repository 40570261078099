import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import SEO from '../SEO/';

import { Globals } from '../../shared/globalStyles';
import theme from '../../tokens/theme';

const Layout = ({ children, siteMetadata, layout, customSEO }) => {
  const {
    logo,
    button_title: buttonTitle,
    button_link: buttonLink,
    address,
    email,
    phone,
    copyright,
    header_nav_items: headerNavItems,
    footer_nav_items: footerNavItems,
    footer_social_items: footerSocialItems
  } = layout;

  return (
    <div style={{ position: 'relative' }}>
      <Globals theme={theme} />
      {!customSEO && <SEO />}
      <Header
        data={{
          navItems: headerNavItems,
          logo,
          button: { buttonTitle, buttonLink }
        }}
      />
      {children}
      <Footer
        data={{
          logo,
          copyright,
          address,
          email,
          phone,
          footerNavItems,
          footerSocialItems
        }}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  siteMetadata: PropTypes.object.isRequired,
  layout: PropTypes.object.isRequired,
  customSEO: PropTypes.bool
};

Layout.defaultProps = {
  customSEO: false
};

export default Layout;

export const query = graphql`
  fragment LayoutFragment on Query {
    prismicLayout {
      data {
        address
        email
        phone
        copyright
        logo {
          alt
          localFile {
            publicURL
          }
        }
        button_title
        button_link {
          type
          uid
          link_type
          url
          target
        }
        header_nav_items {
          link {
            id
            type
            uid
          }
          text
        }
        footer_nav_items {
          text
          link {
            id
            type
            uid
          }
        }
        footer_social_items {
          aria_label
          title
          icon {
            alt
            localFile {
              publicURL
              id
            }
          }
          link {
            url
            target
          }
        }
      }
    }
  }
`;
