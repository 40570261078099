import styled from '@emotion/styled';
import Button from './Button';
import theme from '../../tokens/theme';

const ButtonPrimary = styled(Button)`
  background-color: ${theme.colors.primary};
  color: #fff;
  border-radius: ${theme.radii[5]}px;
  width: 133px;
  transition: all 0.2s;

  &:hover,
  &:active,
  &:focus {
    background-color: rgba(247, 119, 212, 1);
  }
`;

export default ButtonPrimary;
