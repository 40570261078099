const linkResolver = function(doc) {
  if (doc.type === 'home') {
    return '/';
  }
  if (doc.type === 'blog_home') {
    return '/blog';
  }
  if (doc.type === 'blog') {
    return `/blog/${doc.uid}`;
  }
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }
  return '/';
};

module.exports = linkResolver;
