import React from 'react';
import PropTypes from 'prop-types';
import { Plus, Circle } from '../shapes';
import { Box } from '../elements';

const BackgroundCTA = ({ display }) => (
  <Box display={display}>
    <Plus size={52} left={-(42 + 52)} top={-(19 + 52)} color="colors.primary" />
    <Circle
      size={32}
      top={10}
      left={-(153 + 32)}
      color="colors.background.pink"
    />
    <Circle
      size={24}
      top={190}
      left={-(108 + 24)}
      color="colors.background.blue1"
      filled
    />
    <Plus
      size={52}
      right={-(4 + 52)}
      top={170}
      color="colors.background.yellow"
    />
    <Circle size={20} top={150} right={-(200 + 20)} color="colors.primary" />
    <Circle
      size={71}
      top={-30}
      right={-(144 + 71)}
      color="colors.background.blue2"
    />
  </Box>
);

BackgroundCTA.propTypes = {
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

BackgroundCTA.defaultProps = {
  display: 'block'
};

export default BackgroundCTA;
