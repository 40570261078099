import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

const ContentRoot = styled.div`
  margin: ${themeGet('space.4')}px 0;
  & h2 {
    text-align: center;
    margin-bottom: ${themeGet('space.4')}px;
  }

  & p {
    color: ${themeGet('colors.black')};
    margin-bottom: ${themeGet('space.3')}px;
  }

  & a {
    font-weight: bold;
  }
`;

const Text = ({ slice }) => {
  const { content, inverted } = slice.primary;
  return <ContentRoot dangerouslySetInnerHTML={{ __html: content.html }} />;
};

Text.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Text;
