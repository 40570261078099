import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import { Flex, Box, Text } from '../elements';

const QuoteRoot = styled(Box)`
  & .gatsby-image-wrapper {
    border-radius: 100%;
    overflow: hidden;
    filter: grayscale(100%);
  }
`;

const Testimonial = ({ testimonial }) => {
  const {
    quote,
    name,
    job_title: title,
    company,
    photo,
    website_url: websiteURL
  } = testimonial;
  return (
    <QuoteRoot>
      <Text as="h3">"{quote}"</Text>
      <Flex justifyContent="center" alignItems="center" mt={3}>
        <Img fixed={photo.localFile.childImageSharp.fixed} alt={photo.alt} />
        <Box ml={3}>
          <Text fontWeight="bold" mb={1}>
            {name}
          </Text>
          <div>
            {title},{' '}
            {websiteURL ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a href={websiteURL} rel="noopener" target="_blank">
                {company}
              </a>
            ) : (
              company
            )}
          </div>
        </Box>
      </Flex>
    </QuoteRoot>
  );
};

Testimonial.propTypes = {
  testimonial: PropTypes.object.isRequired
};

const Testimonials = ({ slice }) => {
  const { heading } = slice.primary;
  const { items } = slice;
  return (
    <Box as="section" my={6} maxWidth={600} mx="auto">
      <Text as="h2" textAlign="center" mb={5}>
        {heading}
      </Text>
      {items.map(testimonial => (
        <Testimonial key={shortid.generate()} testimonial={testimonial} />
      ))}
    </Box>
  );
};

Testimonials.propTypes = {
  slice: PropTypes.object.isRequired
};

export default Testimonials;
