import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';

const SVGWrapper = styled.div`
  position: absolute;
  z-index: ${props => props.zIndex};
  ${props => props.left && `left: ${props.left}px;`}
  ${props => props.right && `right: ${props.right}px;`}
  ${props => props.top && `top: ${props.top}px;`}
  ${props => props.bottom && `bottom: ${props.bottom}px;`}

  g {
    fill: ${props => themeGet(props.color, 'green')};
  }
`;

const Plus = ({ size, color, left, right, top, bottom, zIndex }) => (
  <SVGWrapper
    color={color}
    left={left}
    right={right}
    top={top}
    bottom={bottom}
    zIndex={zIndex}
  >
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 52 52"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-382.000000, -4853.000000)" fill="#5F0082">
          <path d="M427.970781,4872.97078 L414.029219,4872.97078 L414.029219,4859.02922 C414.029219,4855.70009 411.329133,4853 408,4853 C404.670867,4853 401.970781,4855.70009 401.970781,4859.02922 L401.970781,4872.97078 L388.029219,4872.97078 C384.69908,4872.97078 382,4875.67087 382,4879 C382,4882.32913 384.69908,4885.02922 388.029219,4885.02922 L401.970781,4885.02922 L401.970781,4898.97078 C401.970781,4902.29991 404.670867,4905 408,4905 C411.329133,4905 414.029219,4902.29991 414.029219,4898.97078 L414.029219,4885.02922 L427.970781,4885.02922 C431.299915,4885.02922 434,4882.32913 434,4879 C434,4875.67087 431.299915,4872.97078 427.970781,4872.97078" />
        </g>
      </g>
    </svg>
  </SVGWrapper>
);

Plus.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  right: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  zIndex: PropTypes.number
};

Plus.defaultProps = {
  left: 'auto',
  right: 'auto',
  top: 'auto',
  bottom: 'auto',
  zIndex: -1
};

export default Plus;
