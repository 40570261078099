import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { Box, Text } from '../elements';
import { BackgroundCTA } from '../backgrounds';
import { PrimaryButton } from '../shared/Buttons';

import linkResolver from '../../utils/linkResolver';

const Section = styled(Box)`
  position: relative;
  text-align: center;

  a {
    margin: 0 auto;
  }
`;

const CTABanner = ({ slice }) => {
  const {
    heading,
    subheading,
    action_label: actionLabel,
    action_url: actionURL,
    action_url: { link_type: linkType, url, target }
  } = slice.primary;
  return (
    <Section
      as="section"
      my={6}
      mx="auto"
      width={[1, 1 / 2]}
      flexDirection="column"
      alignItems="center"
    >
      <Text as="p" fontSize={[1]} textAlign="center" my={4} color="primary">
        {subheading}
      </Text>
      <Text as="h2" textAlign="center" mb={4}>
        {heading}
      </Text>
      {linkType === 'Document' ? (
        <PrimaryButton to={linkResolver(actionURL)}>
          {actionLabel}
        </PrimaryButton>
      ) : (
        <PrimaryButton href={url} target={target}>
          {actionLabel}
        </PrimaryButton>
      )}

      <BackgroundCTA display={['none', 'block']} />
    </Section>
  );
};

CTABanner.propTypes = {
  slice: PropTypes.object.isRequired
};

export default CTABanner;
