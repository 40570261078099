import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

import { Flex, Box, Text } from '../elements';
import { PrimaryButton } from '../shared/Buttons';
import linkResolver from '../../utils/linkResolver';

const HeroBannerRoot = styled.div``;

const HeroBanner = ({ slice }) => {
  const {
    heading,
    subheading,
    button_label: buttonLabel,
    button_link: buttonLink,
    image
  } = slice.primary;
  return (
    <HeroBannerRoot>
      <Flex flexDirection={['column-reverse', 'column-reverse', 'row']}>
        <Flex width={[1, 1, 1 / 2]} alignItems="center">
          <Box textAlign={['center', 'center', 'left']}>
            <Text as="h1" mb={3} textAlign={['center', 'center', 'left']}>
              {heading}
            </Text>
            <Text
              as="p"
              fontSize={2}
              lineHeight="24px"
              mb={3}
              textAlign={['center', 'center', 'left']}
            >
              {subheading}
            </Text>
            {buttonLink.link_type === 'Document' ? (
              <PrimaryButton to={linkResolver(buttonLink)}>
                {buttonLabel}
              </PrimaryButton>
            ) : (
              <PrimaryButton
                href={buttonLink.url}
                target={buttonLink.target}
                rel="noopener noreferrer"
              >
                {buttonLabel}
              </PrimaryButton>
            )}
          </Box>
        </Flex>
        <Box width={[1, 1, 1 / 2]}>
          <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        </Box>
      </Flex>
    </HeroBannerRoot>
  );
};

HeroBanner.propTypes = {
  slice: PropTypes.object.isRequired
};

export default HeroBanner;
