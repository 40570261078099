import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import shortid from 'shortid';
import Img from 'gatsby-image';
import { media, em } from '../../shared/util';

import { Flex, Box, Text } from '../elements';
import Browser from '../Browser';
import ButtonPrimary from '../button/ButtonPrimary';

const Wrapper = styled(Box)`
  img {
    width: ${em(30)};
    margin: 0 ${em(15)};
    margin-bottom: ${em(30)};

    ${media.desktop`
      margin: 0;
      margin-right: ${em(30)};
      margin-bottom: ${em(30)};
    `}
  }
`;

const Technologies = ({ title, icons }) => (
  <Wrapper>
    <Text mb={[3]} textAlign={['center', 'center', 'center', 'left']}>
      {title}
    </Text>
    {icons.map(item => (
      <img
        key={shortid.generate()}
        src={item.plugin_image.url}
        alt={item.plugin_image.alt}
      />
    ))}
  </Wrapper>
);

Technologies.propTypes = {
  title: PropTypes.string.isRequired,
  icons: PropTypes.array.isRequired
};

const FeaturedProject = ({ slice }) => {
  const {
    primary: {
      featured_project: {
        document: [
          {
            data: {
              body: [plugins],
              image,
              pre_heading: preHeading,
              description,
              title,
              project_cta: cta
            }
          }
        ]
      }
    }
  } = slice;

  const {
    primary: { title_plugins: pluginsTitle },
    items: icons
  } = plugins;

  const {
    document: [
      {
        data: {
          // description: ctaDescription,
          link: ctaLink,
          title: { text: ctaText }
        }
      }
    ]
  } = cta;

  return (
    <Flex
      id="work"
      as="section"
      mb={6}
      alignItems="center"
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row'
      ]}
      maxWidth={1150}
      mx="auto"
    >
      <Box width={[1, 1, 1, 0.7]} mb={[4, 4, 4, 0]}>
        <Browser maxWidth={642}>
          <Img fluid={image.localFile.childImageSharp.fluid} alt={image.alt} />
        </Browser>

        {/* Text with CTA */}
        <Flex
          display={['flex', 'flex', 'flex', 'none']}
          alignItems="center"
          flexDirection="column"
          mt={4}
        >
          <Technologies title={pluginsTitle} icons={icons} />
          <ButtonPrimary
            href={ctaLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ctaText}
          </ButtonPrimary>
        </Flex>
      </Box>

      <Box flex={1} textAlign={['center', 'center', 'center', 'left']}>
        <Text as="h4" mb={[4]}>
          {preHeading}
        </Text>
        <Text as="h2" mb={[3]}>
          {title}
        </Text>
        <Text as="p" mb={[4]}>
          {description}
        </Text>

        {/* Text with CTA */}
        <Box display={['none', 'none', 'none', 'block']} mt={4}>
          <Technologies title={pluginsTitle} icons={icons} />
          <ButtonPrimary
            href={ctaLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {ctaText}
          </ButtonPrimary>
        </Box>
      </Box>
    </Flex>
  );
};

FeaturedProject.propTypes = {
  slice: PropTypes.object.isRequired
};

export default FeaturedProject;
