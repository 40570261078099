import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from '@styled-system/theme-get';
import Img from 'gatsby-image';

import { Box, Text } from '../elements';

const ParagraphRoot = styled.div`
  max-width: 650px;
  margin: 0 auto;

  & p {
    color: ${themeGet('colors.black')};
    margin-bottom: ${themeGet('space.3')}px;
  }
  & a {
    font-weight: bold;
  }
`;

const CopySectionRoot = styled(Box)`
  & h2 {
    padding-top: ${themeGet('space.5')}px;
  }
  & h3 {
    margin-top: ${themeGet('space.4')}px;
  }

  & ol {
    list-style: decimal;
    line-height: normal;

    li {
      margin-left: 32px;
      margin-bottom: 8px;
    }
  }

  & ul {
    list-style: disc;
    li {
      margin-left: 32px;
      margin-bottom: 8px;
    }
  }

  strong {
    font-weight: bold;
  }
`;

const CopySection = ({ slice }) => {
  const { heading, content, heading_image: headingImage } = slice.primary;

  return (
    <CopySectionRoot as="section" maxWidth={650} mx="auto" mb={5}>
      {heading.html && (
        <Box mb={3} dangerouslySetInnerHTML={{ __html: heading.html }} />
      )}
      {headingImage.localFile && headingImage.localFile.extension === 'png' && (
        <Img
          alt={headingImage.alt}
          fluid={headingImage.localFile.childImageSharp.fluid}
          style={{ marginBottom: '24px' }}
        />
      )}
      <ParagraphRoot dangerouslySetInnerHTML={{ __html: content.html }} />
    </CopySectionRoot>
  );
};

CopySection.propTypes = {
  slice: PropTypes.object.isRequired
};

export default CopySection;
